<template>
	<div class="index-wqg">
		<div class="index-wqga">
			<!-- 社区首页头部轮播图 -->
			<el-carousel :interval="5000" arrow="never">
				<el-carousel-item v-for="(item,index) in adList" :key="index">
					<div class="wqg-item" @click="bindJumpByAd(item)">
						<img :src="item.FilePath" class="wqg-img">
						<div class="wqg-text">
							<div class="wqg-texta">{{item.Subject}}</div>
							<div class="wqg-texte">
								<div class="wqg-textb">{{item.Subject}}&nbsp;</div>
								<!-- <div class="wqg-textc">{{item.urld}}&nbsp;</div>
								<div class="wqg-textd">{{item.urle}}</div> -->
							</div>
						</div>
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>

		<div class="index-wqga">
			<div class="index-mudi">
				<div class="">
					<div class="index-mudia">
						<!-- 社区首页左边目的地旅行顾问  <走马灯> -->
						<div class="index-guwen">目的地旅行顾问</div>
						<div class="index-guwena">
							<marquee id="marquee" scrollAmount=2 scrollDelay=0 direction=up behavior="scroll"
								style="height: 100%;" onmouseover="this.stop()" onmouseout="this.start()">
								<div class="" v-for="(item,index) in Staff" :key="index" @mouseover="marqueeMove()"
									@mouseleave="marqueeOut()">
									<el-tooltip placement="top" effect="light" popper-class="lx">
										<div slot="content" @mouseover="marqueeMove()" @mouseleave="marqueeOut()">
											<div class="index-lxguwena">
												<div class="index-lxguwen">
													<div class="index-xinxin">
														<el-avatar :size="90" :src="item.UserImg"></el-avatar>
														<div class="index-xinxina">
															<img src="../../assets/xinxin.png">
															<div class="index-xinxinb">{{item.UserAVGScore}}</div>
														</div>
													</div>
													<div class="index-lxguwenb">
														<div class="index-lxguwenc">
															<div class="index-lxguwenh">
																<a href="">{{item.StaffName}}</a>
															</div>
															<div class="index-lxguwend">
																<div class="index-lxguwendm">
																	<img src="../../assets/nvxing.png"
																		class="index-lxguwendg" v-show="item.Sex==0">
																	<img src="../../assets/nanxing.png"
																		class="index-lxguwendg" v-show="item.Sex==1">
																</div>
																<div class="index-lxguwenf" title="一起飞®国际旅行顾问">
																	一起飞®国际旅行顾问</div>
															</div>
														</div>
														<div class="index-lxguwene">
															<div class="index-lxguweni">精通领域:</div>
															<div class="index-lxguwenz" :title="item.JingTong">
																{{item.JingTong}}
															</div>
														</div>
													</div>
												</div>

												<div class="index-lxdh">
													<div class="">
														<div class="index-lxdha">
															<div class="">手机号:</div>
															<div class="index-lxdhc">{{item.Phone}}</div>
														</div>
														<div class="index-lxdha">
															<div class="">电子邮箱:</div>
															<div class="index-lxdhc">{{item.Email}}</div>
														</div>
													</div>
													<div class="index-lxdhd">
														<img :src="item.QrcodeImg" class="index-lxdhe">
													</div>
												</div>

												<div class="index-lxdhf">
													<div class="index-lxdhg">
														<a href="">在线向TA提问</a>
													</div>
													<div class="index-lxdhh">
														<a href="" class="index-lxdhi index-lxdhh">
															<img src="../../assets/jiahao.png" class="index-gou">
															<div class="">加关注</div>
														</a>
														<!-- <div class="index-guanz">
															<div class="index-guanza">
																<a href="" class="index-lxdhi index-lxdhh">
																	<img src="../../assets/dagou.png" class="index-gou">
																	<div class="">已关注</div>
																</a>
															</div>
															<div class="index-guanzb">
																<a href="" class="index-lxdhi index-lxdhh">
																	<img src="../../assets/shanchub.png" class="index-gou">
																	<div class="">取消关注</div>
																</a>
															</div>
														</div> -->
														<div class="index-lxdho index-lxdhh">
															<img src="../../assets/lianxi.png" class="index-lx">
															<div class="">QQ</div>
															<div class="">咨询</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div>
											<div class="index-guwenl">
												<div class="index-guwenb">
													<div class="index-guwenc">
														<el-avatar size="large" :src="item.UserImg"></el-avatar>
													</div>
													<div class="index-guwend">
														<div class="index-guwene">
															<div class="index-guwenf index-guwenz">
																{{item.StaffName}}&nbsp;&nbsp;
															</div>
															<div class="index-guweng"><img
																	src="http://static.yiqifei.com/img/emblem/level_5@30X30.PNG"
																	class="index-guwenh" title="V5旅游顾问"></div>
														</div>
														<div class="index-guwene">
															<div class="index-guwenff">精通领域:&nbsp;</div>
															<div class="index-guweni">{{item.JingTong}}</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</el-tooltip>
								</div>
							</marquee>


							<!-- <MARQUEE scrollAmount=2 scrollDelay=0 direction=up onmouseover="this.stop()"
								behavior="slide" onmouseout="this.start()" style="height: 100%;">
								<div class="index-guwenl" v-for="(item,index) in Staff" :key="index">
									<div class="index-guwenb">
										<div class="index-guwenc">
											<el-avatar size="large" :src="item.UserImg"></el-avatar>
										</div>
										<div class="index-guwend">
											<div class="index-guwene">
												<div class="index-guwenf index-guwenz">{{item.StaffName}}&nbsp;&nbsp;</div>
												<div class="index-guweng"><img src="http://static.yiqifei.com/img/emblem/level_5@30X30.PNG" class="index-guwenh" title="V5旅游顾问"></div>
											</div>
											<div class="index-guwene">
												<div class="index-guwenff">精通领域:&nbsp;</div>
												<div class="index-guweni">{{item.JingTong}}</div>
											</div>
										</div>
									</div>
								</div>
							</MARQUEE> -->
						</div>
					</div>
					<div class="index-mudib" v-show="false">
						<!-- 社区首页左下方问答 -->
						<div class="index-wenda">
							<div class="index-wendaa">问答</div>
							<div class="index-wendab">查看更多>></div>
						</div>
						<div class="index-xingxin" v-for="item in [1,2,3,4,5,6]" :key="item">
							<div class="index-xingxina">
								<img src="../../assets/duihua.png" class="index-xingxinb" :title="xingxin">
							</div>
							<div class="index-xingxinc" :title="xingxin">&nbsp;&nbsp;在你们这里订的机票，多久可以载航空公司查询</div>
						</div>
						<div class="index-tiyan">
							<div class="index-tiyana">马上体验</div>
						</div>
					</div>
				</div>
				
				<div class="index-youhui">
					<!-- 社区右边优惠快讯推荐<轮播图> -->
					<div class="index-youhuia" v-show="false">						
						<div class="index-youhuib">
							<div class="index-youhuic">优惠快讯推荐</div>
							<div class="index-youhuid">查看更多>></div>
						</div>
						<div class="index-youhuie">
							<el-carousel indicator-position="outside">
								<el-carousel-item v-for="(item,index) in url" :key="index">
									<div class="wqg-item">
										<img :src="item.urla" class="wqg-img">
										<div class="wqg-text">
											<div class="wqg-texta">{{item.urlb}}</div>
											<div class="wqg-texte">
												<div class="wqg-textb">{{item.urlc}}&nbsp;</div>
												<div class="wqg-textc">{{item.urld}}&nbsp;</div>
												<div class="wqg-textd">{{item.urle}}</div>
											</div>
										</div>
									</div>
								</el-carousel-item>
							</el-carousel>
							<div class="index-youhuif">
								<div class="">换一组</div>
							</div>
						</div>
					</div>
					
					<!-- 社区首页右下方游记选项卡 -->
					<div class="index-youji">
						<div class="index-youjij">
							
							<div class="index-youjia">
								<div class="index-youjib">游记</div>
								<div class="index-youjic" v-for="(item,index) in youji" :key="index">
									<div class="index-youjid" :class="[youjia==index?'index-youjidd':'']"
										@click="btn(index)">{{item.youjia}}</div>
									<div class="index-youjie">{{item.youjib}}</div>
								</div>
							</div>
							<div class="index-youjif"><a href="#/Youji/list">查看更多>></a></div>

						</div>
						<div class="">
							<!-- 社区首页右下方游记选项卡内容 -->
							<div class="index-youjiaoen" v-loading="showYJLoading">
								<div class="index-youjii" v-for="(item,index) in Author" :key="index">
									<div class="index-youjiz" @click="bindJumpDetail(item)">
										<!-- <img :src="item.CoverImagePath" fit="scale-down" class="index-youjik"> -->
										<el-image class="index-youjik" :src="item.CoverImagePath" fit="cover">
										</el-image>
									</div>
									<div class="index-youjil">
										<div class="index-youjill">
											<div class="index-youjin">
												<a href="javascript:;" @click="bindJumpDetail(item)">{{item.Title}}</a>
											</div>
											<div class="index-youjim">
												<div class="index-youjiq">
													<div class="index-guwenc">
														<el-avatar :size="20" :src="item.AuthorFacePath">
														</el-avatar>
													</div>
													<div class="index-nanm">{{item.AuthorName}}</div>
												</div>
												<div class="index-youjima">发表于</div>
												<div class="index-youjima">{{item.TimeEXSpan}}</div>
											</div>
											<div class="index-youjimb">
												<div class="">{{item.Summarize}}
												</div>
											</div>
										</div>

										<div class="index-youjiao">
											<!-- <div class="index-youjiq">
												<div class="index-guwenc">
													<el-avatar size="small" :src="item.AuthorFacePath">
													</el-avatar>
												</div>
												<div class="index-nanm">{{item.AuthorName}}</div>
											</div> -->
											<div class="index-youjiap">
												<!-- <div class="">{{item.TimeEXSpan}}</div> -->
												<div class="index-youjir">
													<div class="index-youjis">
														<img src="../../assets/liulan.png" class="index-youjit">
													</div>
													<div class="">{{item.ViewCount}}</div>
												</div>
												<div class="index-youjir">
													<div class="index-youjis">
														<img src="../../assets/dianzan.png" class="index-youjit">
													</div>
													<div class="">{{item.PlacetCount}}</div>
												</div>
												<div class="index-youjir">
													<div class="index-youjis">
														<img src="../../assets/xingxin.png" class="index-youjit">
													</div>
													<div class="">{{item.CommentCount}}</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="index-fenye">
							<!-- 社区首页右下方游记分页 -->
							<el-pagination v-loading.fullscreen.lock="fullscreenLoading" background
								layout="prev, pager, next" :total="pageSize+10" @current-change="curchange"
								:current-page.sync="currentPage" @prev-click="prevpage" @next-click="nextpage">
							</el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	var util = require('@/utils/util.js');
	var yqfCommon = require('@/utils/yqfCommon.js');
	var datehelper = require('@/utils/datehelper.js');
	var staticData = require('@/data/staticData.js');
	//接口	
	var wqgApi = require('@/api/wqgApi.js');
	var commonApi = require('@/api/commonApi.js');
	//组件引用

	//公共属性
	var that;

	export default {
		components: {
			//组件

		},
		data() {
			return {
				xingxin: '在你们这里订的机票，多久可以载航空公司查询',
				id: '',
				Author: '',
				Staff: '',
				pageindex: 1,
				pageSize: 20,
				fullscreenLoading: false,
				currentPage: 1,
				title: "社区首页",
				url: [{
						urla: 'https://img8.yiqifei.com/20200304/99abdbc8355d479ea74a840eeb4c92b0.jpg!/both/1000x350',
						urlb: '爱尔兰',
						urlc: '本优惠资讯由',
						urld: '一起飞王楚钦',
						urle: '荣誉出品！'
					},
					{
						urla: 'https://img8.yiqifei.com/20200305/b513dc754e164ebd938fcbad6c5a0f99.jpg!/both/1000x350',
						urlb: '马德里',
						urlc: '本优惠资讯由',
						urld: '一起飞王楚钦',
						urle: '荣誉出品！'
					},
					{
						urla: 'https://img8.yiqifei.com/20200306/d5c5d25ca0d744a18fe953ca6d293dfb.jpg!/both/1000x350',
						urlb: '智利',
						urlc: '本优惠资讯由',
						urld: '一起飞王楚钦',
						urle: '荣誉出品！'
					}
				],
				OrderBy: 1,
				youjia: 0,
				youji: [{
						youjia: '最新发表',
						youjib: '|'
					},
					{
						youjia: '热门游记',
						youjib: '|'
					},
					{
						youjia: '精品游记'
					}
				],
				showYJLoading:false,
				adList:null,//广告列表
			};
		},
		created() {
			that = this;
			getAdList();
			GetArticleListByUserCode();
			GetOnlineStaffProfiles();
		},
		methods: {
			bindJumpByAd:function(e){
				window.open(e.LinkURL);
			},
			btn(index) {
				// 社区首页右下方游记选项卡事件
				that.youjia = index
				that.pageindex=1;
				that.currentPage=1;
				if (index == 1) {
					that.OrderBy = 3
					
				}
				else if (index == 2) {
					that.OrderBy = 99
					
				}
				else{
					that.OrderBy = 1
				}
				GetArticleListByUserCode();
			},
			bindJumpDetail(item) {
				that.$router.push({
					path: '/Youji/article',
					query: {
						id: item.ID
					}
				});
			},
			prevpage: function(e) {
				// 社区首页右下方游记分页事件
				this.pageindex = e
				// console.log(this.pageindex)
				const loading = this.$loading({
					lock: true,
					text: '加载中...',
				});
				setTimeout(() => {
					loading.close();
				}, 2000);
				GetArticleListByUserCode();
			},
			nextpage: function(e) {
				// 社区首页右下方游记分页事件
				this.pageindex = e
				// console.log(this.pageindex)
				// console.log(e)
				const loading = this.$loading({
					lock: true,
					text: '加载中...',
				});
				setTimeout(() => {
					loading.close();
				}, 2000);
				GetArticleListByUserCode();
			},
			curchange: function(e) {
				// 社区首页右下方游记分页事件
				this.pageindex = e
				// console.log(this.pageindex)
				const loading = this.$loading({
					lock: true,
					text: '加载中...',
				});
				setTimeout(() => {
					loading.close();
				}, 2000);
				GetArticleListByUserCode();
			},
			marqueeMove: function() {
				// 走马灯
				var marquee = document.getElementById('marquee');
				marquee.stop();
				// console.log('鼠标移入')
			},
			marqueeOut: function() {
				// 走马灯
				var marquee = document.getElementById('marquee');
				marquee.start();
				// console.log('鼠标移出')
			},
			closeMarquee: function() {}
		}
	};

	function GetArticleListByUserCode() {
		that.showYJLoading=true;
		// 游记内容接口
		var parm = {
			Continent: '', //洲代码
			//PageIndex:1,//第几页（默认1开始）
			PageSize: that.pageSize, //总页数
			OrderBy: that.OrderBy, //排序方式(1、创建时间 2、浏览量 3、评论数 4、点赞数 99、无序 默认为按创建时间)
			PageIndex: that.pageindex
		};
		wqgApi.GetArticleListByUserCode(parm, function(result) {
			that.showYJLoading=false;
			console.log(result);
			if (result.code == 0) {
				that.Author = result.data.ArticleList
				console.log(result.data.ArticleList)
				console.log(parm)
			}
			// console.log('游记内容接口')
		})
	}
	//获取旅行顾问
	function GetOnlineStaffProfiles() {
		var parm = {
			id: ''
		};
		wqgApi.GetOnlineStaffProfiles(parm, function(result) {
			console.log(result);
			if (result.code == 0) {
				that.Staff = result.data.StaffContacts
				console.log(result.data.StaffContacts)
			} else {

			}
		})
	}
	//获取广告
	function getAdList() {
		// uni.showLoading({
		//   title: '加载中',
		// });		
		let parm = {
			id: 118
		};
		commonApi.GetAdList(parm, function(result) {
			console.log(result);
			if (result.code == 0) {
				that.adList = result.data.adList;
			} else {
				// util.wxalert(result.msg);
			}
		});
	}
</script>

<style lang="scss">
	@import '../../style/youji/index.scss';
</style>
