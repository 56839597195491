/*通用接口*/
import {postAjaxRequest} from '@/utils/request'
import {yqfApiurl,yqfComApiurl} from '@/utils/webconfig'

//获取广告
export function GetAdList(parm, callback) {
	console.log('GetAdList');
	postAjaxRequest('/CommonAPI/GetAdList', parm, function(result) {
		callback(result);
	})	
}