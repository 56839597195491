/*我去过API接口*/
import {getAjaxRequest,postAjaxRequest} from '@/utils/request'
import {yqfApiurl} from '@/utils/webconfig'



//文章列表
/*首页文章接口*/
export function GetArticleListByIndex(parm, callback) {
    console.log('GetArticleListByIndex');
    postAjaxRequest('/WQGApi/GetArticleListByIndex', parm, function(result) {
        callback(result);
    })	
}
/*
parm={
	PageIndex:1,//第几页（默认1开始）
	PageSize:20,//总页数
	OrderBy:1,//排序方式(1、创建时间 2、浏览量 3、评论数 4、点赞数 99、无序 默认为按创建时间)
};
*/
export function GetArticleListByUserCode(parm, callback) {
	console.log('GetArticleListByUserCode');
	postAjaxRequest('/WQGApi/GetArticleListByUserCode', parm, function(result) {
		callback(result);
	})	
}
//文章详情
/*
parm={
	id:'',//文章详情ID	
};
*/
export function GetArticleDetailByID(parm, callback) {
    console.log('GetArticleDetailByID');
    postAjaxRequest('/WQGApi/GetArticleDetailByID', parm, function(result) {
        callback(result);
    })	
}
//获取在线旅行顾问
export function GetOnlineStaffProfiles(parm, callback) {
    console.log('GetOnlineStaffProfiles');
    postAjaxRequest('/WQGApi/GetOnlineStaffProfiles', parm, function(result) {
        callback(result);
    })	
}
//获取新鲜事
export function GetFreshInfos(parm, callback) {
    console.log('GetFreshInfos');
    postAjaxRequest('/WQGApi/GetFreshInfos', parm, function(result) {
        callback(result);
    })	
}

//获取旅行顾问的信息
export function GetEmployeeInfo(parm, callback) {
    console.log('GetEmployeeInfo');
    postAjaxRequest('/WQGApi/GetEmployeeInfo', parm, function(result) {
        callback(result);
    })	
}

//获取点赞列表
export function GetArticleVoteList(parm, callback) {
    console.log('GetArticleVoteList');
    postAjaxRequest('/WQGApi/GetArticleVoteList', parm, function(result) {
        callback(result);
    })	
}

//点赞
export function ArticleVoteAdd(parm, callback) {
    console.log('ArticleVoteAdd');
    postAjaxRequest('/WQGApi/ArticleVoteAdd', parm, function(result) {
        callback(result);
    })	
}